<template>
    <b-modal
      id="bill-items-modal"
      hide-header
      hide-footer
      centered
      size="xl"
      @show="onShow"
      @hidden="onHidden"
    >
      <div class="header">
        <div class="header-content">
          <p>Conta a receber</p>
          <Close class="close-icon" @click="closeModal" />
        </div>
      </div>
      <div class="body">
  
        <b-form-group v-if="!form.id">
          <label>
            Adicionar item
          </label>
          <ItemSelector
            :item="newItemForm.item"
            @select="onNewItemSelected"
            noResultMessage="Nenhum procedimento encontrado"
          />
        </b-form-group>
  
        <div class="items-title">Itens ({{ form.bill_items.length }})</div>
        <BillItemCard
          v-for="(billItem, index) in form.bill_items"
          :key="billItem.id"
          :validated="validated"
          :cortesia="cortesia"
          :billItem="billItem"
          :healthPlans="healthPlans"
          :allPlans="allPlans"
          :disabled="Boolean(billItem.id)"
          @remove="removeBillItem(index)"
        />
  
        <GlobalDiscountOrAdditionModule
          v-if="showGeneralDiscountOrAddition"
          :billItems="form.bill_items"
        />
  
        <div class="end-footer-pre-total">
          <b-button
            v-if="!form.id"
            v-can="'FiConRec4'"
            class="wh-button"
            variant="primary-outline"
            @click="() => (showGeneralDiscountOrAddition = !showGeneralDiscountOrAddition)"
          >
            {{ showGeneralDiscountOrAddition ? 'Ocultar' : 'Aplicar' }}  desc. / acrésc. geral
          </b-button>
  
          <ValueTotal
            label="Valor total:"
            :value="getTotalItems()"
          />
        </div>
        <hr />
  
        <div class="row">
          <div class="col-4">
            <b-form-group>
              <label for="field_date" id="title-styles">
                Data do atendimento
                <span class="help">(opcional)</span>
              </label>
              <date-picker
                class="full"
                v-model="form.service_date"
                format="DD/MM/YYYY"
                placeholder="DD/MM/AAAA"
                :clearable="false"
                :lang="langDatePicker"
                :disabled="billId ? true : false" 
              ></date-picker>
            </b-form-group>
            </div>
            <div class="col-8">
              <BeneficiarySelect
                optional
                label="Favorecido"
                v-model="form.beneficiary"
                :value="form.beneficiary"
                :options="beneficiaries"
              />
            </div>
        </div>
  
  
        <b-form-group>
          <label for="field_description" id="title-styles">
            Descrição
            <span class="help">(opcional)</span>
          </label>
          <b-form-input
            autocomplete="off"
            v-model="form.description"
            placeholder="Adicionar observações"
            :disabled="Boolean(form.id)"
          />
        </b-form-group>

        <div class="col-12" v-if="!!patientCredit && canLoadCreditPatient">
							<div class="d-flex justify-content-between align-items-center border rounded px-3 py-1">

								<div class="d-flex flex-row align-items-center justify-content-center m-0">
									<b-form-checkbox
									id="use_patient_credit"
									v-model="usePatientCredit"
									switch
									class="use-patient-credit"
                  :disabled="!patientCredit?.credit > 0 || !!billId"
									>
									<span class="label">Crédito do paciente</span>
									</b-form-checkbox>
								
								</div>
								<div class="credit-available" v-if="!billId">
									Crédito atual {{ creditFormatted }}
								</div>
							</div>
				</div>

						<div class="col-12 row" v-if="usePatientCredit && canLoadCreditPatient && !billId">
							<b-form-group class="col-6">
                <label for="credit_amount" class="d-flex flex-row justify-content-between align-itens-center">
                  Valor a ser utilizado
                  <b-form-checkbox
                    id="use_credit_total"
                    v-model="useCreditTotal"
                    class="use-credit-total"
                    :disabled="!usePatientCredit || !!billId"
                  >
                    <span class="use-credit__label">
                      Utilizar crédito total
                    </span>
                  </b-form-checkbox>
                </label>
								<money
									class="form-control"
									v-model="creditAmount"
									:disabled="!usePatientCredit || !!billId"
                  @onChange="handleChangeCreditAmount"
								/>
							</b-form-group>
							<b-form-group class="col-6">
								<label for="balance">Saldo</label>
								<money
									class="form-control"
									:value="balanceValue"
									:disabled="true"
								/>
                <div v-if="validated && isCreditBalanceNegative" class="custom-invalid-feedback">
                  O saldo não pode ficar negativo
                </div>
							</b-form-group>
						</div>

        <Payments
          ref="payments"
          useTax
          :validated="validated"
          :cortesia="cortesia"
          :bill="form"
          :totalAmount="getTotalItems()"
          :hasCreditPatient="creditAmount > 0"
        />

        <div
          class="checkbox-wrapper"
          v-show-feature="'nota-fiscal'"
          v-if="showFeature('Notas Fiscais') && !issuedNFSe"
        >
          <Check :value="issueInvoice" @input="handlerIssueInvoice" />
          <div>Desejo emitir uma NFS-e (Nota Fiscal de Serviço Eletrônico)</div>
        </div>

        <div class="end-footer" :class="{ space: form.id, end: !form.id }">
          <b-button
            v-if="form.id"
            v-can="'FiConRec5'"
            class="wh-button danger"
            variant="danger-outline"
            @click="onDeleteClick"
          >
            Apagar conta
          </b-button>

          <div>
            <b-button
              v-if="issuedNFSe"
              v-can="'FiConRec5'"
              class="view-invoice__button mr-3"
              @click="downloadNFSePDF"
            >
              <IssuedNfeIcon class="mr-1"/>
              Visualizar NFS-e
            </b-button>
            <b-button
              v-if="showFinancialButton()"
              class="wh-button"
              variant="primary"
              @click="!form.id ? createBillToReceive() : updateBillToReceive()"
            >
              {{ !form.id ? 'Salvar' : 'Atualizar' }} conta a receber 
              {{ issueInvoice ? 'e emitir NFS-e' : '' }}
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

  <script>
  import { EventBus } from '@/utils/eventBus';
  import apiNF from '@/modules/nfe/api'
  import financialApi from '@/modules/financial/api'
  import { track } from '@/utils/mixPanel'
  import { getBillItemNetValue } from '../utils/financialHelper'
  import { getCurrentClinic } from '@/utils/localStorageManager'
  import { showFeature, showFinancialButton, hasPermissionPatientCreditCategory } from '@/utils/permissionsHelp';
  import { parseNumberToMoney } from '@/utils/moneyHelper'
  
  export default {
    components: {
      BeneficiarySelect: () => import('@/components/General/BeneficiarySelect.vue'),
      BillItemCard: () => import('@/modules/financial/components/BillItemCard.vue'), 
      Check: () => import ('@/components/General/Check'),
      Close: () => import('@/assets/icons/close.svg'), 
      GlobalDiscountOrAdditionModule: () => import('@/modules/financial/components/GlobalDiscountOrAdditionModule.vue'),
      ItemSelector: () => import('@/components/General/ItemSelector.vue'), 
      Payments: () => import('@/modules/financial/components/Payments.vue'),
      IssuedNfeIcon: () => import('@/assets/icons/nf-issued-icon.svg'),
      ValueTotal: () => import('@/components/General/ValueTotal.vue'), 
    },
    props: {
      billId: String,
      patient: Object,
      checkedItems: Array,
      appointmentDate: Date,
      creditPatientCategory: Object
    },
    mounted() {
      this.getCategories()
    },
    data() {
      return {
        clinic: getCurrentClinic(),
        validated: false,
        cortesia: false,
        showGeneralDiscountOrAddition: false,
        itemCategory: null,
        procedureCategory: null,
        productCategory: null,
        form: this.getDefaultForm(),
        healthPlans: [],
        allPlans: [],
        newItemForm: {},
        availableProcedures: [],
        beneficiaries: [],
        issuedNFSe: false,
        issueInvoice: false,
        langDatePicker: {
          formatLocale: {
            weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
          }
        },
        usePatientCredit: false,
        creditAmount: 0,
        patientCredit: null,
        useCreditTotal: false,
        alreadySent: false,
        validateAttendance: false,
      }
    },
    computed: {
      creditFormatted() {
		  	return `R$${parseNumberToMoney(this.patientCredit?.credit).trim().split('$')[1] }`
		  },
      isCreditPatientCategory() {
        return this.form.category?.name === 'Depósito de crédito (paciente)'
      },
      balanceValue() {
		  	return this.patientCredit?.credit - this.creditAmount || 0
		  },
      isCreditBalanceNegative() {
        return this.usePatientCredit && this.balanceValue < 0
      },
      canLoadCreditPatient() {
        return this.showFeature('Caução do paciente') && this.hasPermissionPatientCreditCategory()
      },
    },
    methods: {
      showFeature,
      showFinancialButton,
      hasPermissionPatientCreditCategory,
      onShow() {
        if (this.patient) {
          this.form.patient = this.patient
        }
        this.clearCreditForm()
        this.getPatientHealthPlans()
        if (this.checkedItems?.length) {
          this.form.bill_items = []          
          this.checkedItems.map(checkedItem => {
            this.form.bill_items.push({
              appointment_item_id: checkedItem.id,
              category_id: this.getCategory(checkedItem),
              appointment_id: checkedItem.appointment_id,
              health_plan: checkedItem.health_plan,
              plan: checkedItem.plan,
              item: checkedItem.item,
              item_id: checkedItem.item_id,
              item_type: checkedItem.item_type,
              value: checkedItem.value,
              discount: 0,
              addition: 0,
              format: '$',
              final_value: checkedItem.value,
              net_value: checkedItem.value,
              item_profiles: checkedItem.item_profiles,
            })
          })

          this.form.service_date = this.moment(this.checkedItems.sort((a, b) => this.moment(a.date, 'YYYY-MM-DD HH:mm:ss').isBefore(this.moment(b.date, 'YYYY-MM-DD HH:mm:ss')) ? 1 : -1)[0].appointment.start_datetime).toDate();
        }
        if ((this.creditPatientCategory || this.form || !this.form.id) && this.canLoadCreditPatient) {
          this.getCreditPatient()
        }
      },
      onHidden() {
        this.clearForm()
        this.$emit('hidden')
      },
      getDefaultForm(){
        return {
          id: null,
          type: 'bill_to_receive',
          category_id: null,
          patient: this.patient,
          service_date: this.appointmentDate || Date.now(),
          beneficiary: null,
          description: null,
          amount: 0,
          discount: null,
          currency: null,
          final_amount: 0,
          net_value: 0,
          payments: [],
          bill_items: [],
          creditAmount: 0,
          patientCredit: null          
        }
      },
      getTotal() {
        let total = 0
				this.form.bill_items.forEach(billItem => {
					total +=billItem.final_value
				});

        if (this.creditAmount > 0) {
          total = total - this.creditAmount
        }
        return parseFloat(total.toFixed(2))
      },
      getTotalItems() {
        let total = 0
				this.form.bill_items.forEach(billItem => {
					total +=billItem.final_value
				});

        
        return parseFloat(total.toFixed(2))
      },
      closeModal() {
        this.$bvModal.hide('bill-items-modal')
      },
      getPatientHealthPlans() {
        if (!this.clinic.id || !this.form.patient) return
        this.api.getPatientHealthPlans(this.clinic.id, this.form.patient.id)
        .then(res =>  {
          this.healthPlans = res.data.map(el => el.health_plan)
          this.allPlans = res.data.filter(data => data.plan_id).map(el => el.plan)
        })
      },
      parseFormData() {
        return {
          type: 'bill_to_receive',
          clinic_id: this.clinic.id,
          category_id: this.itemCategory.id,
          patient_id: this.form.patient.id,
          service_date: this.form.service_date || null,
          beneficiary_id: this.form.beneficiary ? this.form.beneficiary.id : null,
          description: this.form.description,
          amount: this.getAmount(),
          discount: this.getDiscount(),
          currency: this.getCurrency(),
          final_amount: this.getTotal(),
          payments: this.form.payments.map(payment => {
            const paymentPayload = {
              amount: payment.amount,
              due_date: payment.due_date,
              bank_account_id: payment.bank_account.id,
              payment_method_id: payment.payment_method.id,
              installments: payment.installments
            };
            
            if (payment.id) {
              paymentPayload.id = payment.id;
            }

            return paymentPayload
          }),
          bill_items: this.form.bill_items.map(billItem => ({
            id: billItem.id,
            health_plan_id: billItem.health_plan.id,
            plan_id: billItem.plan?.id,
            category_id: billItem.category_id,
            appointment_id: billItem.appointment_id,
            item_id: billItem.item_id,
            item_type: billItem.item_type,
            appointment_item_id: billItem?.appointment_item_id,
            value: billItem.value,
            discount: billItem.discount,
            addition: billItem.addition,
            format: billItem.format,
            final_value: billItem.final_value,
            net_value: billItem.net_value,
            item_profiles: billItem?.item_profiles?.filter(itemProfile => itemProfile.profile && itemProfile.beneficiary)
              .map(itemProfile => ({
                id: itemProfile.id || null,
                bill_item_id: itemProfile.bill_item_id || null,
                item_id: billItem.item.id,
                profile_id: itemProfile.profile.id,
                beneficiary_id: itemProfile.beneficiary.id,
              }))
          })),
          creditPatientAmount: this.creditAmount > 0 ? this.creditAmount : null,
          creditPatientCategory: this.creditPatientCategory?.id
        };
      },
      removeBillItem(index) {
        this.form.bill_items.splice(index, 1)
      },
      validateValues(){
        const negativePayments = this.form.payments.filter(payment => parseFloat(payment.amount) < 0)
        const negativeBillItems = this.form.bill_items.filter(billItem => { 
          return parseFloat(billItem.final_value) < 0 || parseFloat(billItem.net_value) < 0 || parseFloat(billItem.discount) < 0 || parseFloat(billItem.addition) < 0
        })

        return (
          !negativePayments?.length &&
          !negativeBillItems?.length
        )
      },
      async createBillToReceive() {
        if (!this.isValidForm() && !this.cortesia) return;
        if(this.alreadySent) return;
        this.alreadySent = true;
        if(!this.validateValues()){
          this.$toast.error('Os valores inseridos na conta devem ser positivos!');
          return
        }

        const data = this.parseFormData();

        if(data.amount === 0){
          data.payments[0].installments = [{
            amount: 0.00,
            change: "auto",
            installment_count: 1,
            installment_value: 0.00,
            payment_date: this.moment().toDate(),
            position: 0,
            status: "paid_out",
            tax: 0
          }]
        }
        const isLoading = this.$loading.show();
        try {
          const res = await this.api.createBill(data);
          
          track('conta_a_receber', {
            clinic: this.clinic.name,
            total: this.form.final_amount
          });
          this.form.payments.map(payment => {
            track('metodo_pagamento', {
              clinic: this.clinic.name,
              payment_method: payment.payment_method.type,
              total: payment.amount
            });
          })

          const total = this.form?.payments?.reduce((acc, payment) => {
            acc += payment.amount
            return acc
          }, 0)

          if(this.issueInvoice) {
            const issueInvoiceParams = {
              bill_id: res.data.id,
              servico: {
                aliquota: '',
                valor_servicos: total
              },
              tomador: {
                nome: this.patient?.name,
                email: this.patient?.email,
                cnpj_cpf: this.patient?.cpf,
                patient_id: this.patient?.id
              }
            }

            this.$store.dispatch('nfe/updateNfModalData', issueInvoiceParams)
            this.$store.dispatch('nfe/updateIsPatientDisabled', true)
            this.$emit('issueInvoice');
          }
          this.solvePendencies()
          setTimeout(() => {
            this.clearForm();
            this.closeModal()
            this.$emit('reload');
            this.$emit('clearItems');
            this.$toast.success('Conta cadastrada com sucesso!');
					}, "200")
          this.alreadySent = false;
        } catch(err) {
          this.$toast.error(err.message);
          this.alreadySent = false;
        } finally {
          isLoading.hide();
        }
      },
      async solvePendencies(){
        const isLoading = this.$loading.show();
        const data = {
          ids: this.checkedItems.map(i => i.id)
        }
        try {
          await this.api.solvePendenciesByIds(data);
        } catch(err) {
          this.$toast.error(err.message);
        } finally {
          isLoading.hide();
        }
      },
      async updateBillToReceive() {
        if (!this.isValidForm()) return;
        if(!this.validateValues()){
          this.$toast.error('Os valores inseridos na conta devem ser positivos!');
          return
        }
        
        const data = this.parseFormData();
        const isLoading = this.$loading.show();
        try {
          await this.api.updateBill(this.form.id, data);

          if(this.issueInvoice) {
            const issueInvoiceParams = {
              bill_id: this.billId,
              servico: {
                aliquota: '',
                valor_servicos: this.getTotal()
              },
              tomador: {
                nome: this.patient?.name,
                email: this.patient?.email,
                cnpj_cpf: this.patient?.cpf,
                patient_id: this.patient?.id,
              }
            }

            this.$store.dispatch('nfe/updateNfModalData', issueInvoiceParams)
            this.$store.dispatch('nfe/updateIsPatientDisabled', true)
            this.$emit('issueInvoice');
          }

          this.clearForm();
          this.$toast.success('Conta atualizada com sucesso!');
          EventBus.$emit('reloadBillsToReceive');
          this.closeModal()
          this.$emit('reload');
        } catch(err) {
          this.$toast.error(err.message);
        } finally {
          isLoading.hide();
        }
      },
      async onDeleteClick() {
        this.$swal({
          icon: 'question',
          html: `<div class="body-alert">Deseja realmente excluir esta conta? Essa ação não poderá ser desfeita.</div>`,
          showCancelButton: true,
          confirmButtonColor: '#F63220',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
         }).then(res => {
          if (res.isConfirmed) {
            const isLoading = this.$loading.show();
            try {
              this.api.deleteBill(this.form.id);
              this.$toast.success('Conta apagada com sucesso');
              this.$emit('reload');
              EventBus.$emit('reloadBillsToReceive');
              this.closeModal();
            } catch(ex) {
              this.$toast.error(ex.message);
            } finally {
              isLoading.hide();
            }
          }
        })
      },
      isValidForm() {
        this.validated = true;
        this.cortesia = false
        const cortesia = this.form.bill_items.some(cortesia => cortesia?.health_plan?.fantasy_name.toLowerCase().includes('cortesia'))
        if(cortesia) {
          this.cortesia = true
          return this.itemCategory &&
            this.form.patient &&
            this.form.payments.every(payment =>
              payment.payment_method &&
              payment.bank_account &&
              payment.due_date &&
              payment.installments.length
            ) &&
            this.form.bill_items.every(billItem =>
              billItem.item_id &&
              billItem.item_type &&
              billItem.health_plan &&
              billItem.format
            ) &&
            this.isValidSumAmount() &&
            this.cortesia
        } else {
          return this.itemCategory &&
            this.form.patient &&
            this.form.payments.every(payment =>
              payment.payment_method &&
              payment.bank_account &&
              payment.amount &&
              payment.due_date &&
              payment.installments.length
            ) &&
            this.form.bill_items.every(billItem =>
              billItem.item_id &&
              billItem.health_plan &&
              billItem.value &&
              billItem.format &&
              billItem.final_value
            ) &&
            this.isValidSumAmount() && !this.isCreditBalanceNegative
        }
      },
      isValidSumAmount() {
        let total = 0
				this.form.payments.forEach(payment => {
					total += payment.amount
				});

        if (this.creditAmount > 0) {
          total = total - this.creditAmount
        }
        if ( parseFloat(total.toFixed(2)) !== this.getTotal()) {
          this.$toast.warning('A soma dos métodos de pagamento deve ser igual ao valor final')
          return false
        }
        return true
      },
      getCategory(checkedItem) {
      switch(checkedItem.item_type) {
        case 'App\\Models\\ClinicProcedure':
          return this.procedureCategory.id
        case 'App\\Models\\Warehouse\\Product':
          return this.productCategory.id
        default:
          return this.itemCategory.id
        }
      },
      getCategories() {
          this.api.getCategories(this.clinic.id, 'Entrada', false, true)
          .then(({ data}) => {
            this.itemCategory = data.find(el => el.name === 'Itens')
            this.procedureCategory = data.find(el => el.name === 'Procedimento')
            this.productCategory = data.find(el => el.name === 'Produto')
          })
      },
      clearForm() {
        this.validated = false
        this.issueInvoice = false
        this.form = this.getDefaultForm()
        this.clearCreditForm()
      },
      onNewItemSelected(item) {
        this.form.bill_items.push({
          category_id: this.getCategory(item),
          appointment_id: null,
          health_plan: null,
          plan: null,
          item: item,
          item_id: item.id,
          item_type: item.item_type,
          value: 0,
          discount: 0,
          addition: 0,
          format: '$',
          final_value: 0,
        })
      },
      calculateBillItemNetValue() {
        if (this.form.id) return
        this.form.bill_items.map(billItem => {
          billItem.net_value = getBillItemNetValue(this.form, billItem)
        })
        this.form.bill_items = [...this.form.bill_items]
        this.$forceUpdate()
      },
      handlerIssueInvoice($event) {
        this.issueInvoice = $event
      },
      async downloadNFSePDF() {
        try {
          const { data } = await apiNF.getNFbyId(this.form.nfs_id)
          if (data.caminho_pdf_nota_fiscal) {
            window.open(data.caminho_pdf_nota_fiscal, '_blank')
          } else {
            this.$toast.warning('Nota em processo de autorização, aguarde!')
          }
        } catch (err) {
          this.$toast.error(err.message)
        }
      },
      async getCreditPatient() {
        try {
          if (!this.canLoadCreditPatient) return;
          const category = this.form.id ? this.form.category?.id : this.creditPatientCategory?.id || this.creditPatientCategory
          if (this.patientCredit || !this.form?.patient?.id || !category) return ;
          const response = await financialApi.getCreditPatient(this.clinic.id, this.form.patient.id, category)

          if (response.data) {
            this.patientCredit = response.data
          }
        } catch (error) {
          console.error(error)
        }
		  },
      handleChangeCreditAmount(value) {
        this.form.creditDiscount = value
      },
      clearCreditForm() {
        this.usePatientCredit = false
        this.creditAmount = 0
        this.patientCredit = null
      },
      handleUseTotalCredit(value) {
        if (value && this.patientCredit.credit <= this.getTotal()){
          this.creditAmount = this.patientCredit.credit
        } else if (value) {
          this.creditAmount = this.getTotal()
        }
      },

      handlePatientCreditPayment(toInsert) {
        if (toInsert) {
          this.$refs.payments.handleCreditPatientPayment(this.creditAmount)
        } else {
          this.$refs.payments.removeCreditPatientPayment()
        }
      },
      getDiscount() {
        if (!this.creditAmount > 0) return 0
        const discount = this.form.payments.filter(payment => payment?.payment_method?.name === 'Crédito do paciente').reduce((acc, payment) => {
          acc += payment.amount
          return acc
        }, 0)

        return parseFloat(discount.toFixed(2))
      },
      getCurrency() {
        if (!this.creditAmount > 0) return null
        return 'R$'
      },
      getAmount() {
        let total = 0
				this.form.bill_items.forEach(billItem => {
					total +=billItem.final_value
				});

        return parseFloat(total.toFixed(2))
      },
      validateStatusAttendance() {
        const billItems = this.form?.bill_items;

        if (billItems && billItems.length > 0) {
          billItems.forEach(item => {
              const appointment = item?.appointment;
              if (['EXAMINATING', 'FINISHED', 'HAPPENING', 'DILATING', 'IN_PRE_CONSULTATION', 'PRE_FINISHED'].includes(appointment?.status)) {
                this.validateAttendance = true
              }
          });
        }
      }
    },
    
    watch: {
      async billId(id) {
        if (id) {

          const loading = this.$loading.show()
          try {
            const res = await this.api.getBill(id)

            res.data.nfs_id ? this.issuedNFSe = true : this.issuedNFSe = false

            this.form = {
              ...res.data,
              service_date: res.data.service_date ? this.moment(res.data.service_date).toDate() : null
            }
            if (res.data?.bill_patient_credit) {
              this.creditAmount = res.data.bill_patient_credit.bill_credit.amount
              this.usePatientCredit = true

              if (this.canLoadCreditPatient && this.creditPatientCategory) {
                this.getCreditPatient()
              }
            }
            this.validateStatusAttendance()
          } catch (err) {
            this.$toast.error(err.message)
          } finally {
            loading.hide()
          }
        }
      },
      'form.payments': {
        handler() {
          this.form?.payments?.length && this.calculateBillItemNetValue()
        },
        deep: true,
        immediate: true,
      },
      creditAmount: function(value) {
        this.form.creditDiscount = value
        this.$refs.payments.setCrediPatientAmount(value)
      },
      'usePatientCredit': function(value) {
        if (value) {
          this.handlePatientCreditPayment(true)
        } else {
          this.form.creditDiscount = 0
          this.creditAmount = 0
          this.useCreditTotal = false
          this.handlePatientCreditPayment(false)
        }
      },
      useCreditTotal: function(value) {
        this.handleUseTotalCredit(value)
      },
      patient: function(value) {
        if (value) {
          this.form.patient = value
          this.getPatientHealthPlans()
          this.getCreditPatient()
        }
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import '@/assets/scss/main';

  #bill-items-modal {
    .modal-content {
      .modal-body {
        padding: 0;
  
        .header {
          border-bottom: 1px solid var(--neutral-200);
          .header-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 16px 24px;
            p {
              font-weight: 600;
              font-size: 16px;
              color: var(--type-active);
            }
            .close-icon {
              fill: var(--neutral-500);
              height: 24px;
              width: 24px;
            }
          }
        }
        .body {
          padding: 20px;
          .use-patient-credit {
          label {
            color: #0C1D59;
            font-weight: bold;
          }

          .use-credit__label {
           font-size: 16px;
           font-weight: 400;
           color: #525C7A;
          }
        }

        .credit-available {
          color: #069763;
          font-weight: bold;
        }
          .end-footer-pre-total {
            display: flex;
            gap: 10px;
            justify-content: flex-end;
          }
          .end-footer {
            display: flex;
            gap: 10px;
  
            &.space {
              justify-content: space-between;
            }
  
            &.end {
              justify-content: flex-end;
            }
  
            .danger {
              color: var(--states-error);
            }

            .view-invoice__button {
              font-weight: 700;
              background: transparent;
              color: var(--neutral-500);
              border-radius: $border-radius-base;
              border: 2px solid var(--neutral-500);
            }
          }
          .items-title {
            font-weight: 700;
            font-size: 16px;
            color: var(--dark-blue);
          }
        }
      }
    }
  }
  </style>
  